import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Css/custom.css";
import { useNavigate } from "react-router-dom";
import { uuidv4 } from "../Component/utils";
import logo from "../Images/logo.png";

export const Landing = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [language, setLanguage] = useState("en");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [operator, setOperator] = useState("mobily");
  const [extRef, setExtRef] = useState(null);

  const navigate = useNavigate();

  const handleContinue = () => {
    //const evinaRequestId = uuidv4();
    let applicationId = "194";

    //const evinaRequestId = uuidv4();
    let evinaRequestId;
    if (extRef === null) {
      evinaRequestId = uuidv4();
    } else {
      evinaRequestId = extRef;
    }

    if (!operator) {
      setNotificationMessage("Operator is required");
      setShowNotification(true);
      return;
    }

    if (mobileNumber && operator == "zain") {
      axios
        .post("https://www.zaincallback.visiontrek.in/zain/api/requestpin", {
          mobileNumber,
          language,
          evinaRequestId,
          operator,
          applicationId,
        })
        .then((response) => {
          console.log("Data sent successfully", response.data);

          if (response.data.response === "Success") {
            navigate("/otp", {
              state: { mobileNumber, evinaRequestId, operator },
            });
          } else if (response.data.response == "4") {
            window.location.href = "https://quizbox.gamewins.co.za/";
          } else {
            console.log("Something went wrong!");
            setNotificationMessage(response.data.response);
            console.log(
              "After setting notification message:",
              notificationMessage
            );

            setIsSubmitted(true);
          }
        })
        .catch((error) => {
          console.error("Error sending data to backend", error);
        });
      return;
    }

    if (mobileNumber) {
      axios
        .post("https://www.backend.quizboxdemo.visiontrek.in/ani", {
          mobileNumber,
          language,
          evinaRequestId,
          operator,
          applicationId,
        })
        // .post("http://localhost:8686/ani", { mobileNumber, language, evinaRequestId, operator, applicationId})
        .then((response) => {
          console.log("Data sent successfully", response.data);

          if (response.data.response === "Success") {
            navigate("/otp", {
              state: { mobileNumber, evinaRequestId, operator },
            });
          } else if (response.data.response == "4") {
            window.location.href = "https://quizbox.gamewins.co.za/";
          } else {
            console.log("Something went wrong!");
            setNotificationMessage(response.data.response);
            console.log(
              "After setting notification message:",
              notificationMessage
            );

            setIsSubmitted(true);
          }
        })
        .catch((error) => {
          console.error("Error sending data to backend", error);
        });
    } else {
      setShowNotification(true);
    }
  };

  const toggleLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ext_ref = urlParams.get("ext_ref");
    setExtRef(ext_ref);
    console.log(ext_ref, "msp");
    return () => {
      // Reset the state when the component unmounts
      setIsSubmitted(false);
      setShowNotification(false);
    };
  }, []);

  return (
    <div className="landing-page">
      <header>
        <img src={logo} alt="Logo" className="logo" />

        <div className="language-toggle">
          <button
            onClick={() => toggleLanguage("en")}
            className={language === "en" ? "active px-5 py-1.5" : ""}
          >
            EN
          </button>
          <button
            onClick={() => toggleLanguage("ar")}
            className={language === "ar" ? "active px-5 py-1.5" : ""}
          >
            AR
          </button>
        </div>
      </header>
      <main>
        {isSubmitted ? (
          <div>
            <p style={{color:"#fff"}}>{notificationMessage}</p>
          </div>
        ) : (
          <div>
            <p className="bold-text">
              {language === "en"
                ? "Enter your mobile number "
                : "أدخل رقم هاتفك المحمول"}
            </p>

            <div className="input-container mt-3">
              <input
                className="py-0"
                type="text"
                placeholder={language === "en" ? "Mobile Number" : "رقم الجوال"}
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                required // Adding the required attribute
              />
              <select
                className={`operator-select selector`}
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Select Operator
                </option>
                <option value="zain">Zain</option>
                <option value="mobily">Mobily</option>
              </select>
            </div>

            {/* Notification */}
            {showNotification && (
              <div className="notification">
                <p className="error-text">
                  {language === "en"
                    ? "Please enter your mobile number."
                    : "الرجاء إدخال رقم هاتفك المحمول."}
                </p>
              </div>
            )}

            <button onClick={handleContinue}>
              {language === "en" ? "Subscribe" : "إشترك"}
            </button>

            <div
              className={`${
                language === "en"
                  ? "disclaimer-section"
                  : "disclaimer-section right"
              }`}
            >
              <h2>
                {language === "en"
                  ? "Terms and Conditions"
                  : "الشروط و الاحكام"}
              </h2>
              <ul
                className={` ${
                  language === "en" ? "points" : "right-align-list"
                }`}
              >
                <li>
                  {language === "en"
                    ? "Quizbox is available to Zain Customers for 8.625 riyals renewed weekly (including value-added tax) and to Mobily customers for 2.3 riyals renewed daily (including value-added tax),automatic renewal applies."
                    : "خدمة Quizbox متاحة لعملاء زين بسعر 8.625 ريال سعودي يتم تجديدها أسبوعيًا (بما في ذلك ضريبة القيمة المضافة) ولعملاء موبايلي بسعر 2.3 ريال سعودي يتم تجديدها يوميًا (بما في ذلك ضريبة القيمة المضافة)، ويتم التجديد التلقائي."}

                  {/* {language === "en"
                    ? "This service is available for Mobily customers for 2.3 SAR renewed daily (including VAT). To cancel your subscription, please send U 75 to 690001."
                    : "هذه الخدمة متوفرة لعملاء موبايلي مقابل ٢.٣ ريال تتجدد  يوميا (شامل قيمة الضريبة المضافة) لالغاء اشتراكك الرجاء ارسال غ ٧٥ الى ٦٩٠٠٠١"} */}
                </li>
                <li>
                  {language === "en"
                    ? "To cancel your subscription, please send U 75 to 690001 for Mobily customers and U 53 To 705717 for Zain customers."
                    : "لإلغاء اشتراكك، يرجى إرسال U 75 إلى 690001 لعملاء موبايلي وU 53 إلى 705717 لعملاء زين."}
                </li>
                {/* <li>
                  {language === "en"
                    ? "To cancel the subscription for Zain customers, please send U 53 to 705717."
                    : "لإلغاء الاشتراك لعملاء زين، يرجى إرسال U 53 إلى 705717."}
                </li> 
                <li>
                  {language === "en"
                    ? "To cancel your subscription, please send U 75 to 690001."
                    : "لإلغاء الاشتراك لعملاء زين، يرجى إرسال غ 53 إلى 705717."}
                </li>*/}

                <li>
                  {language === "en"
                    ? " The Tax amount was collected to prepaid customers upon charging."
                    : " تم تحصيل مبلغ الضريبة لعملاء مسبق الدفع عند عملية شحن الرصيد"}
                </li>
                <li>
                  {language === "en"
                    ? "Subscribers of this service enjoy access to unlimited content."
                    : "يستمتع مشتركو هذه الخدمة بالوصول إلى محتوى غير محدود."}
                </li>
                <li>
                  {language === "en"
                    ? "To use this service, you must be 18+ years old or have received permission from your parents or a person authorized to pay your bill."
                    : "لاستخدام هذه الخدمة، يجب أن تكون عمرك 18+ عامًا أو تلقيت إذنًا من والديك أو الشخص المفوض لدفع فاتورتك."}
                </li>
              </ul>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};
