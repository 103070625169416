import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Landing } from '../Component/Landing';
import { Otpsend } from '../Component/Otpsend';
import LoginForm from '../Component/LoginForm';



export const Routing = () => {
  return (
    <>
    
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Landing/>} exact={true}></Route>
            <Route path="/otp" element={<Otpsend/>} exact={true}></Route>
            <Route path="/login" element={<LoginForm/>} exact={true}></Route>
        </Routes>
        
        </BrowserRouter>
    
    
    </>
  )
}
