import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Css/custom.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

const ENDPOINT_URL = "https://ksg.intech-mena.com/MSG/v1.1/API/GetAggScript";

export const Otpsend = () => {
  const [code, setCode] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [language, setLanguage] = useState("en");
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const timestamp = Date.now();

  //const [scriptContent, setScriptContent] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const mobileNumber = location.state ? location.state.mobileNumber : "";
  const evinaRequestId = location.state ? location.state.evinaRequestId : "";
  const operator = location.state ? location.state.operator : "";
  const [antiFrauduniqid, setAntiFrauduniqid] = useState("");

  console.log(operator, "operator...");

  useEffect(() => {
    async function getScript() {
      setLoading(true);

      console.log("hitting the script....");

      try {
        let operatorId = operator;
        if (operator === "mobily") {
          operatorId = "16";
        }
        if (operator === "zain") {
          operatorId = "8";
        }
        console.log("request id in otp--- ", evinaRequestId);
        const getScriptURL = `${ENDPOINT_URL}?applicationId=193&countryId=207&requestId=${evinaRequestId}&TimeStamp%20=${timestamp}&channelid=99933518&ai=70&operatorid=${operatorId}`;
        // const getScriptURL = `${ENDPOINT_URL}?applicationId=193&countryId=207&requestId=${evinaRequestId}`;
        console.log("script url--- ", evinaRequestId);
        const response = await fetch(getScriptURL);

        if (!response.ok) {
          throw new Error(
            `Failed to fetch script: ${response.status} ${response.statusText}`
          );
        }

        const scriptContent = await response.json();
        console.log("response----- " + scriptContent["AntiFrauduniqid"]);
        setAntiFrauduniqid(scriptContent["AntiFrauduniqid"]);

        console.log("response " + scriptContent[100]);

        if (scriptContent) {
          let top_head = document.getElementsByTagName("head")[0];
          let anti_script = document.createElement("script");

          anti_script.innerHTML = scriptContent[100];
          top_head.insertBefore(anti_script, top_head.firstChild);

          var event = new Event("DCBProtectRun");
          // console.log(event);
          document.dispatchEvent(event);
          document.addEventListener("gateway-load", (event) => {
            //Enable form submission
            setScriptLoaded(true);
            console.log(event, "EVENT LOADED");
          });
        }
      } catch (error) {
        console.error("Error fetching script", error);
      } finally {
        setLoading(false);
      }
    }
    if (operator == "zain") {
      setLoading(false);
      setScriptLoaded(true);
      return;
    } else {
      getScript();
    }
  }, [operator]);

  const handleContinue = () => {
    let applicationId = "194";

    if (code && operator == "zain") {
      axios
        .post("https://www.zaincallback.visiontrek.in/zain/api/validate", {
          mobileNumber,
          code,
          language,
          evinaRequestId,
          operator,
          antiFrauduniqid,
          applicationId,
        })
        .then((response) => {
          console.log("Data sent successfully", response.data);

          if (response.data.response === "Success") {
            window.location.href =
              "https://quizbox.gamewins.co.za/?serviceId=8";
          } else {
            console.log("Something went wrong!");
            setNotificationMessage(response.data.response);
            console.log(
              "After setting notification message:",
              notificationMessage
            );

            setIsSubmitted(true);
          }
        })
        .catch((error) => {
          console.error("Error sending data to backend", error);
        });
      return;
    }

    if (code) {
      axios
        .post("https://www.backend.quizboxdemo.visiontrek.in/otp", {
          mobileNumber,
          code,
          language,
          evinaRequestId,
          operator,
          antiFrauduniqid,
          applicationId,
        })
        //  .post("http://localhost:8686/otp", {
        //   mobileNumber, code, language, evinaRequestId,operator,antiFrauduniqid,applicationId
        // })
        .then((response) => {
          console.log("Data sent successfully", response.data);

          if (response.data.response === "Success") {
            window.location.href = "https://quizbox.gamewins.co.za/";
          } else {
            console.log("Something went wrong!");
            setNotificationMessage(response.data.response);
            console.log(
              "After setting notification message:",
              notificationMessage
            );

            setIsSubmitted(true);
          }
        })
        .catch((error) => {
          console.error("Error sending data to backend", error);
        });
    } else {
      setShowNotification(true);
    }
  };

  const toggleLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  return (
    <div className="landing-page bg-gray-300 h-screen">
      <header>
        <h1 className="text-3xl font-bold py-5">
          {language === "en"
            ? "Welcome to Our OTP Page"
            : "مرحبًا بك في صفحة OTP الخاصة بنا"}
        </h1>
        <div className="language-toggle">
          <button
            onClick={() => toggleLanguage("en")}
            className={language === "en" ? "active px-5 py-1.5" : ""}
          >
            EN
          </button>
          <button
            onClick={() => toggleLanguage("ar")}
            className={language === "ar" ? "active  px-5 py-1.5" : ""}
          >
            AR
          </button>
        </div>
      </header>
      {scriptLoaded && !loading && (
        <main>
          {isSubmitted ? (
            <div>
              {/* <p>
                {language === "en"
                  ? "Something went wrong or the OTP is Invalid."
                  : "حدث خطأ أو الرمز OTP غير صحيح."}
              </p> */}
              <p className="message" style={{color:"#fff"}}>{notificationMessage}</p>
            </div>
          ) : (
            <div>
              <div className="input-container">
                <input
                  type="text"
                  placeholder={
                    language === "en" ? "Confirm OTP" : "تأكيد رمز OTP"
                  }
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </div>

              {/* Notification */}
              {showNotification && (
                <div className="notification">
                  <p className="error-text">
                    {language === "en"
                      ? "Please confirm the OTP."
                      : "يرجى تأكيد رمز OTP."}
                  </p>
                </div>
              )}

              <button onClick={handleContinue} id="evina_ctabutton">
                {language === "en" ? "Confirm" : "تأكيد"}
              </button>
            </div>
          )}
        </main>
      )}
      {/* {scriptContent && (
        <script dangerouslySetInnerHTML={{ __html: scriptContent }}></script>
      )} */}
      {(!scriptLoaded || loading) && <Loader loading={true} />}
    </div>
  );
};
