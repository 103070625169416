import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const LoginForm = () => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const mobileNumber = location.state ? location.state.mobileNumber : "";

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    mobileNumber: mobileNumber,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
       // .post("http://88.99.5.236:8786/ani", { mobileNumber, language })
        "http://88.99.5.236:8686/api/login",
       // "http://localhost:8686/api/login",
        formData
      );

      if (response.data.response === "Success") {
        window.location.href = "https://apiquizdemo.gamewins.co.za/";
      } else {
        setErrorMessage(
          "Login failed. Please check your credentials and try again."
        );
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage(
        "An error occurred during login. Please try again later."
      );
    }
  };

  return (
    <div className="mx-auto justify-center bg-gray-200 h-screen">
      <div className="flex justify-center items-center w-full py-20 md:py-[120px]">
        <div
          className={`w-3/4 max-[1000px]:3/4 md:w-1/4 max-[700px]:mx-10 max-[700px]:mx-10 max-[500px]:mx-4 bg-black rounded-lg`}
        >
          <div className="w-full">
            <h1 className="text-center font-merri font-semibold text-3xl tracking-widest p-4 bg-gradient-to-r from-blue-500 via-red-500 to-green-500 text-transparent bg-clip-text">
              K&C LOGIN
            </h1>
            <div className="flex justify-center items-center">
              <div className="border-b-2 border-b-red-500 w-1/4"></div>
            </div>
          </div>
          <div className="mx-10 mt-8 mb-10">
            <form onSubmit={handleSubmit}>
              <div className="relative z-0  w-full mb-6 group">
                <input
                  type="text"
                  name="username"
                  id="username"
                  className="block py-4 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-white focus:outline-none focus:ring-0 focus:border-white peer"
                  placeholder=" "
                  required
                  onChange={handleChange}
                />
                <label
                  htmlFor="username"
                  className="peer-focus:font-medium absolute text-sm text-gray-300 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-white peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  User Name
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="block py-4 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-white focus:outline-none focus:ring-0 focus:border-white peer"
                  placeholder=" "
                  required
                  onChange={handleChange}
                />
                <label
                  htmlFor="password"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-white peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Password
                </label>
              </div>

              <div className="flex justify-center">
                <button type="submit">Login</button>
              </div>
              {errorMessage && (
                <div className="text-red-500 text-center mt-4">
                  {errorMessage}
                </div>
              )}
              <div className="flex justify-center items-center"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
